<template>
    <div
      id="visualization"
      style="background-color: white; "
      :style="{ height: treeMapHeight + 'px' }"
    ></div>
</template>

<script>
import { cloneDeep } from "lodash";
import EventBus from "@/EventBus";
import axios from "axios";
export default {
  props: {},
  data() {
    return {
      foamtree: null,
      CateData: null,
      treeMapHeight: 1
    };
  },

  mounted() {
    this.cateData = cloneDeep(this.$store.getters.getCategoryData);

    // 텝이 선택된 상태로 접속 했을 때
    if (this.$route.query.section && this.$route.query.section !== "전체") {
      this.cateData = this.cateData.filter(
        cateData => this.$route.query.section === cateData.section_name
      );
      this.filterCateData(35);
      this.treeMapHeight = this.getDynamicHeightSize();
      // 텝이 전체로 선택되어있을 때
    } else {
      this.filterCateData(15);
      this.treeMapHeight = this.getDynamicHeightSize();
    }
    this.appendData();
    this.renderingTreeMap();
  },

  created() {
    // PeriodTab.vue 에서 온 이벤트 등록
    EventBus.$on("reloadTreeMap", selectedSection => {
      this.$store.getters.getTreeMapInstance.dispose();
      this.cateData = cloneDeep(this.$store.getters.getCategoryData);
      if (selectedSection !== "전체") {
        // 전체 외를 클릭했을 때
        this.filterCateData(35);
        this.cateData = this.cateData.filter(
          cateData => selectedSection === cateData.section_name
        );
        this.appendData();
      } else {
        // 전체를 클릭했을 때
        this.filterCateData(15);
        this.appendData();
      }
      this.treeMapHeight = this.getDynamicHeightSize();

      // #visualization에 data-foamtree="embedded" 속성이 할당되어 에러뜨는 부분 처리
      $("#visualization").attr("data-foamtree", "");
      this.renderingTreeMap();
    });

    // 브라우져 width 사이즈 변경에 맞춰서 treemap resize
    window.onresize = () => {
      this.$store.getters.getTreeMapInstance.resize();
    };
  },
  updated() {
    // 텝 눌렀을 때 height이 리사이즈 되면서, 안쪽에 treemap도 함께 Resize해줘야함
    this.$store.getters.getTreeMapInstance.resize();
  },
  methods: {
    routerToSecondCatePage: function(group) {
      const firstCate = group.parent === undefined ? group.label : group.parent;
      const secondCate = group.parent === undefined ? null : group.label;
      this.$router
        .push({
          name: "ExploreContentSelectSecondThirdCategory",
          query: {
            dataSet: this.$store.getters.getSelectedDataSet,
            section: this.$route.query.section,
            firstCate: firstCate,
            secondCate: secondCate
          },
          params: {}
        })
        .catch(err => {});
    },
    filterCateData: function(end) {
      this.cateData.forEach((ele, index) => {
        ele.groups = ele.groups.slice(0, end);
      });
    },
    appendData: function() {
      this.cateData.forEach((ele, index) => {
        ele.weight = Math.pow(Math.log(ele.weight), 3); // 카테고리 사이즈
        ele.groups.forEach(e => {
          e.selected = false;
          e.weight = 1;
        });
      });
    },
    renderingTreeMap: function() {
      const foamtree = new window.CarrotSearchFoamTree({
        id: "visualization",
        dataObject: {
          groups: this.cateData
        },
        layout: "squarified",
        stacking: "flattened",
        relaxationQualityThreshold: 2.5,

        // Disable animation
        fadeDuration: 500,
        rolloutDuration: 0,
        pullbackDuration: 0,

        // Use the custom web font for FoamTree texts.
        groupLabelFontFamily: "Lato",
        groupLabelMaxFontSize: 24,
        groupLabelMinFontSize: 1,

        // width사이즈에 맞춰서 treemap resize
        relaxVisible: true,

        // Customize borders, fill and strokes
        groupBorderWidth: 4,
        groupInsetWidth: 6,
        groupBorderRadius: 0.2,
        groupBorderRadiusCorrection: 1,

        groupSelectionOutlineWidth: 3.5,

        groupFillType: "gradient",
        groupFillGradientRadius: 3,
        groupFillGradientCenterLightnessShift: 22,

        groupStrokeWidth: 0.33,
        groupStrokeType: "plain",
        groupStrokePlainLightnessShift: 0,

        groupUnexposureSaturationShift: -100,
        groupUnexposureLightnessShift: 200,

        // Allow some more time to draw
        finalCompleteDrawMaxDuration: 1000,
        finalIncrementalDrawMaxDuration: 1000,

        // Make the line spacing and the total height of the
        // label smaller than the default to make space for
        // the coverage value display
        groupLabelLineHeight: 1.0,

        groupLabelDarkColor: "rgba(0, 0, 0, 0.8)",

        groupColorDecorator: function(opts, props, vars) {
          vars.groupColor = props.group.color;
          vars.labelColor = "rgb(0,0,0)";
        },
        groupLabelLayoutDecorator: function(opts, props, vars) {
          if (props.group.groups !== null) {
            vars.verticalPadding = 0;
            vars.maxTotalTextHeight = 1.0;
            vars.fontFamily = "Lato";
            vars.fontWeight = "500";
          }
        },

        onGroupDoubleClick: function(event) {
          event.preventDefault();
          if (event.group.groups === undefined) {
            this.routerToSecondCatePage(event.group);
          }
        },
        onGroupMouseWheel: function(event) {
          event.preventDefault();
        },
        onGroupClick: event => {
          event.preventDefault();
          if (event.group.groups === undefined) {
            this.routerToSecondCatePage(event.group);
          }
        },
        onGroupHover: event => {
          if (event.group !== null && event.group.parent !== undefined) {
            EventBus.$emit(
              "updateExploreStepBarFirstCateAndSecondCate",
              event.group.parent,
              event.group.label
            );
          }
        }
        // onGroupHold: event => {
        //   const group = event.group;
        //   const category = group.parent === null ? group.label : group.parent;
        //   const selected = group.parent === null ? null : group.label;
        //   this.$router.push({
        //     name: "ExploreContentSecondCategory",
        //     query: {
        //       category: category,
        //       selected: selected
        //     },
        //     params: {
        //       processBarStep: this.$props.step
        //     }
        //   });
        //   event.preventDefault();
        // }
      });
      this.$store.commit("setTreeMapInstance", foamtree);
    },
    getDynamicHeightSize: function() {
      const cellNum = this.cateData[0].groups.length;
      const cateNum = this.cateData.length;
      return cellNum * cateNum * 6;
    }
  },

  destroyed() {
    this.$store.getters.getTreeMapInstance.dispose();
  }
};
</script>

<template>
  <div>
    <v-container style="max-width: none">
      <v-row>
        <v-col cols="12" style="    padding: 0;">
          <OntologySectionTab></OntologySectionTab>
          <TreeMap></TreeMap>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <BarChart></BarChart>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TreeMap from "@/components/explore_content/first_page/TreeMap.vue";
import OntologySectionTab from "../../../components/explore_content/first_page/OntologySectionTab";
import BarChart from "../../../components/explore_content/first_page/BarChart";

export default {
  name: "firstCate",
  components: {
    TreeMap,
    OntologySectionTab,
    BarChart,
  },
  data() {
    return {};
  },
  mounted() {
    // 주소로 접근할 때 dataSet을 입력하지 않았으면 자동으로 입력해준다.
    if (!('dataSet' in this.$route.query)){
      this.$router.push({
        query : {
            dataSet : this.$store.getters.getSelectedDataSet,
          section : "전체",
        }
      })
    }
  },
  methods : {

  }
};
</script>
<style scoped></style>

<template>
  <div class="tabbed round">
    <ul id="tree-map-tab">
      <li
        v-for="(ontology, n) in qOntologyList"
        :key="n"
        :class="{ active: ontology === section }"
        style="background-color:#ffffff; font-family: 'Nanum Gothic', sans-serif; box-sizing: border-box; display: inline-block; font-size: 18px; padding: 11px; font-weight: bold; width: auto; color: #3c4a64"
        v-on:click="clickEvent(ontology)"
      >
        {{ ontology }}
      </li>
    </ul>
  </div>
</template>

<script>
import EventBus from "../../../EventBus";
export default {
  name: "selectOntologySection",
  data() {
    return {
      qOntologyList: null,
      section: this.$route.query.section
    };
  },
  created() {
    EventBus.$on("updateOntologyList",() => {
      this.getOntologyList();
    });
  },
  mounted() {
    this.getOntologyList();
  },
  methods: {
    getOntologyList: function() {
      const sectionSet = new Set();
      sectionSet.add("전체");
      this.$store.getters.getCategoryData.forEach(obj =>
        sectionSet.add(obj.section_name)
      );
      this.qOntologyList = sectionSet;
    },
    clickEvent: function(selectedOntology) {
      this.$router
        .push({
          query: {
            dataSet : this.$store.getters.getSelectedDataSet,
            section: selectedOntology
          }
        })
        .catch(err => {});
    },
    // 트리맵 관련 작동은 트리맵에서만 책임지도록 분리
    sendSelectedOntologyToTreeMap: function(selectedOntology) {
      EventBus.$emit("reloadTreeMap", selectedOntology);
      EventBus.$emit("reloadExploreContentBarChart", selectedOntology);
    },
    setSelectedOntology: function(ontology) {
      this.section = ontology;
    }
  },
  watch: {
    $route(to, from) {
      // eventbus 작동
      this.sendSelectedOntologyToTreeMap(this.$route.query.section);
      // 클릭한 온톨로지 처리
      this.setSelectedOntology(this.$route.query.section);
    }
  }
};
</script>
<style scoped>
/* Tabbed Styles */
.tabbed {
  min-width: 400px;
  margin: 0 auto;
  border-bottom: 4px solid #3c4a64;
  overflow: hidden;
  transition: border 250ms ease;
}
.tabbed ul {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  padding-left: 48px;
  list-style-type: none;
}
.tabbed ul * {
  margin: 0px;
  padding: 0px;
}
.tabbed ul li {
  display: block;
  float: left;
  padding: 10px 24px 8px;
  background-color: #fff;
  margin-right: 46px;
  z-index: 2;
  position: relative;
  cursor: pointer;
  color: #777;

  text-transform: uppercase;
  font: 600 13px/20px roboto, "Open Sans", Helvetica, sans-serif;

  transition: all 250ms ease;
}
.tabbed ul li:before,
.tabbed ul li:after {
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 44px;
  background-color: #fff;
  transition: all 250ms ease;
}
.tabbed ul li:before {
  z-index: -1;
  right: -24px;
  transform: skew(30deg, 0deg);
  box-shadow: rgba(0, 0, 0, 0.1) 3px 2px 5px,
    inset rgba(255, 255, 255, 0.09) -1px 0;
}
.tabbed ul li:after {
  z-index: -1;
  left: -24px;
  transform: skew(-30deg, 0deg);
  box-shadow: rgba(0, 0, 0, 0.1) -3px 2px 5px,
    inset rgba(255, 255, 255, 0.09) 1px 0;
}

.tabbed ul li.active {
  z-index: 3;
}
.tabbed ul li.active,
.tabbed ul li.active:before,
.tabbed ul li.active:after {
  background-color: #3c4a64;
  color: #fff;
}

/* Round Tabs */
.tabbed.round ul li {
  border-radius: 8px 8px 0 0;
}
.tabbed.round ul li:before {
  border-radius: 0 8px 0 0;
}
.tabbed.round ul li:after {
  border-radius: 8px 0 0 0;
}

a.iprodev {
  line-height: normal;
  font-family: Varela Round, sans-serif;
  font-weight: 600;
  text-decoration: none;
  font-size: 13px;
  color: #a7aaae;
  position: fixed;
  left: 20px;
  bottom: 20px;
  border: 1px solid #a7aaae;
  padding: 12px 20px 10px;
  border-radius: 50px;
  transition: all 0.1s ease-in-out;
  text-transform: uppercase;
}
a.iprodev:hover {
  background: #ffffff;
}
.active {
  color: white !important;
  background-color: #3c4a64 !important;
}
</style>

<template>
  <div style="padding: 6px">
    <h3
      style="font-family: 'Nanum Gothic Coding', monospace; margin: 15px; color: #3c4b64"
    >
      ● 전체 카테고리 빈도
    </h3>
    <v-divider style="margin-top: 80px; margin-bottom: 50px;"></v-divider>
    <canvas
      id="barChart"
      style="background-color: white; width: auto;"
    ></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import EventBus from "../../../EventBus";

export default {
  name: "CategoryFrequencyBarChart",
  data() {
    return {
      chart: null,
      chartConfig: null
    };
  },
  created() {
    // PeriodTab.vue 에서 온 이벤트 등록

    EventBus.$on("reloadExploreContentBarChart", selectedSection => {
      const labelsArray = [];
      const weightArray = [];
      const colorArray = [];

      let cateData = this.$store.getters.getCategoryData;

      if (selectedSection !== "전체") {
        cateData = cateData.filter(
          cateData => selectedSection === cateData.section_name
        );
      }
      cateData.forEach(element => {
        labelsArray.push(element.label);
        weightArray.push(element.weight);
        colorArray.push(element.groups[0].color);
      });

      this.chart.data.labels = labelsArray;
      this.chart.data.datasets[0].data = weightArray;
      this.chart.data.datasets[0].backgroundColor = colorArray;
      this.chart.data.datasets[0].borderColor = colorArray;
      this.chart.update();
    });
  },
  mounted() {
    const ctx = document.getElementById("barChart").getContext("2d");
    const labelsArray = [];
    const weightArray = [];
    const colorArray = [];

    let cateData = this.$store.getters.getCategoryData;
    if (this.$route.query.section !== "전체") {
      cateData = cateData.filter(
        cateData => this.$route.query.section === cateData.section_name
      );
    }

    cateData.forEach(element => {
      labelsArray.push(element.label);
      weightArray.push(element.weight);
      colorArray.push(element.groups[0].color);
    });

    this.chartConfig = {
      type: "bar",
      data: {
        responsive: true,

        labels: labelsArray,
        datasets: [
          {
            label: "전체 카테고리 빈도",
            data: weightArray,
            backgroundColor: colorArray,
            borderColor: colorArray,

            borderWidth: 1
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    };
    this.chart = new Chart(ctx, this.chartConfig);
  },
  destroyed() {
  }
};
</script>

<style scoped></style>
